/**
 * Sharing
 *
 * Visualizza i pulsanti di condivisione quando il viewport raggiunge
 * il data-offset impostato sull’elemento .js-share
 *
 * @see {@link libraries/util.js}
 * @see {@link https://codyhouse.co/ds/components/app/back-to-top}
 */

(function (w, d) {
    var share = d.querySelector('.js-sharing');

    if (share) {
        var scrollOffset = parseInt(share.getAttribute('data-offset'), 10) || 0;
        var scrolling = false;

        function checkStickyShare() {
            var windowTop = w.scrollY || d.documentElement.scrollTop;
            Util.toggleClass(share, 'is-visible', windowTop >= scrollOffset);
            scrolling = false;
        }

        if (scrollOffset > 0) {
            w.addEventListener("scroll", function () {
                if (!scrolling) {
                    scrolling = true;

                    if (w.requestAnimationFrame) {
                        w.requestAnimationFrame(checkStickyShare);
                    } else {
                        setTimeout(checkStickyShare, 250);
                    }
                }
            });

            checkStickyShare();
        }
    }
}(window, document));
