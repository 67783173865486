/**
 * Share Counter
 */
(function(w, d) {
    function humanizeNumber(num) {
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1) + 'M';
        }

        if (num >= 1e3) {
            return (num / 1e3).toFixed(1) + 'k';
        }

        return num;
    }

    var countButtons = d.querySelectorAll('.js-share-count');
    var totalContainer = d.querySelector('.js-share-total');
    var totalValue = 0;

    if (countButtons.length) {
        var shareUrl = null;
        countButtons.forEach(function (button) {
            if (button.dataset.url) {
                shareUrl = button.dataset.url;
            }
        });

        if (shareUrl && 'fetch' in w && 'templateUrl' in w) {
            fetch(templateUrl + '/share-count/count-stats.php?url=' + encodeURIComponent(shareUrl))
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data.message) {
                        console.error(data.message);

                        return;
                    }

                    countButtons.forEach(function (button) {
                        var counter = d.createElement('span');
                        Util.addClass(counter, 'c-share__count');
                        counter.innerText = humanizeNumber(data.share[button.dataset.sharer]);
                        //button.parentElement.appendChild(counter);
                        button.appendChild(counter);

                        totalValue += parseInt(data.share[button.dataset.sharer], 10);
                    });

                    if (totalContainer) {
                        totalContainer.innerText = humanizeNumber(totalValue);
                    }
                });
        }
    }
})(window, document);