/**
 * Anchor Scroll
 *
 * @see https://gist.github.com/HoundstoothSTL/5510082
 */
(function (w, d) {
    var html = d.querySelector('html');

    function getOffsetTop(element) {
        var offsetTop = 0;

        do {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        } while (element);

        return offsetTop;
    }

    function scrollToAnchor(hash) {
        var target = d.querySelector(hash);

        if (target) {
            var offsetTop = getOffsetTop(target);

            setTimeout(function () {
                html.scrollTop = offsetTop;
            });
        }
    }

    d.addEventListener('DOMContentLoaded', function () {
        if (w.location.hash) {
            scrollToAnchor(w.location.hash);
        }
    });
})(window, document);
