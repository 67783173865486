/**
 * Page About
 *
 * 01. Timeline
 */

(function (w, d) {
    /**
     * Timeline
     *
     * @requires plugins/timeline.js
     *
     * @see https://www.cssscript.com/responsive-horizontal-vertical-timeline/
     */
    (function () {
        timeline(d.querySelectorAll('.js-timeline'), {
            forceVerticalMode: 899,
            mode: 'horizontal',
            verticalStartPosition: 'left',
            visibleItems: 3
        });
    }());
}(window, document));
