// File#: _1_menu
// Usage: codyhouse.co/license
(function() {
    var Menu = function(element) {
        this.element = element;
        this.elementId = this.element.getAttribute('id');
        this.menuItems = this.element.getElementsByClassName('js-menu__content');
        this.trigger = document.querySelectorAll('[aria-controls="'+this.elementId+'"]');
        this.selectedTrigger = false;
        this.menuIsOpen = false;
        this.initMenu();
        this.initMenuEvents();
    };

    Menu.prototype.initMenu = function() {
        // init aria-labels
        for(var i = 0; i < this.trigger.length; i++) {
            Util.setAttributes(this.trigger[i], {'aria-expanded': 'false', 'aria-haspopup': 'true'});
        }
        // init tabindex
        for(var i = 0; i < this.menuItems.length; i++) {
            this.menuItems[i].setAttribute('tabindex', '0');
        }
    };

    Menu.prototype.initMenuEvents = function() {
        var self = this;
        for(var i = 0; i < this.trigger.length; i++) {(function(i){
            self.trigger[i].addEventListener('click', function(event){
                event.preventDefault();
                // if the menu had been previously opened by another trigger element -> close it first and reopen in the right position
                if(Util.hasClass(self.element, 'menu--is-visible') && self.selectedTrigger !=  self.trigger[i]) {
                    self.toggleMenu(false, false); // close menu
                }
                // toggle menu
                self.selectedTrigger = self.trigger[i];
                self.toggleMenu(!Util.hasClass(self.element, 'menu--is-visible'), true);
            });
        })(i);}

        // keyboard events
        this.element.addEventListener('keydown', function(event) {
            // use up/down arrow to navigate list of menu items
            if( !Util.hasClass(event.target, 'js-menu__content') ) return;
            if( (event.keyCode && event.keyCode == 40) || (event.key && event.key.toLowerCase() == 'arrowdown') ) {
                self.navigateItems(event, 'next');
            } else if( (event.keyCode && event.keyCode == 38) || (event.key && event.key.toLowerCase() == 'arrowup') ) {
                self.navigateItems(event, 'prev');
            }
        });
    };

    Menu.prototype.toggleMenu = function(bool, moveFocus) {
        var self = this;
        // toggle menu visibility
        Util.toggleClass(this.element, 'menu--is-visible', bool);
        this.menuIsOpen = bool;
        if(bool) {
            this.selectedTrigger.setAttribute('aria-expanded', 'true');
            Util.moveFocus(this.menuItems[0]);
            this.element.addEventListener("transitionend", function(event) {Util.moveFocus(self.menuItems[0]);}, {once: true});
            // position the menu element
            this.positionMenu();
            // add class to menu trigger
            Util.addClass(this.selectedTrigger, 'menu-control--active');
        } else if(this.selectedTrigger) {
            this.selectedTrigger.setAttribute('aria-expanded', 'false');
            if(moveFocus) Util.moveFocus(this.selectedTrigger);
            // remove class from menu trigger
            Util.removeClass(this.selectedTrigger, 'menu-control--active');
            this.selectedTrigger = false;
        }
    };

    Menu.prototype.positionMenu = function(event, direction) {
        var selectedTriggerPosition = this.selectedTrigger.getBoundingClientRect(),
            menuOnTop = (window.innerHeight - selectedTriggerPosition.bottom) < selectedTriggerPosition.top;
        // menuOnTop = window.innerHeight < selectedTriggerPosition.bottom + this.element.offsetHeight;

        var left = selectedTriggerPosition.left,
            right = (window.innerWidth - selectedTriggerPosition.right),
            isRight = (window.innerWidth < selectedTriggerPosition.left + this.element.offsetWidth);

        var horizontal = isRight ? 'right: '+right+'px;' : 'left: '+left+'px;',
            vertical = menuOnTop
                ? 'bottom: '+(window.innerHeight - selectedTriggerPosition.top)+'px;'
                : 'top: '+selectedTriggerPosition.bottom+'px;';
        // check right position is correct -> otherwise set left to 0
        if( isRight && (right + this.element.offsetWidth) > window.innerWidth) horizontal = 'left: '+ parseInt((window.innerWidth - this.element.offsetWidth)/2)+'px;';
        var maxHeight = menuOnTop ? selectedTriggerPosition.top - 20 : window.innerHeight - selectedTriggerPosition.bottom - 20;
        this.element.setAttribute('style', horizontal + vertical +'max-height:'+Math.floor(maxHeight)+'px;');
    };

    Menu.prototype.navigateItems = function(event, direction) {
        event.preventDefault();
        var index = Util.getIndexInArray(this.menuItems, event.target),
            nextIndex = direction == 'next' ? index + 1 : index - 1;
        if(nextIndex < 0) nextIndex = this.menuItems.length - 1;
        if(nextIndex > this.menuItems.length - 1) nextIndex = 0;
        Util.moveFocus(this.menuItems[nextIndex]);
    };

    Menu.prototype.checkMenuFocus = function() {
        var menuParent = document.activeElement.closest('.js-menu');
        if (!menuParent || !this.element.contains(menuParent)) this.toggleMenu(false, false);
    };

    Menu.prototype.checkMenuClick = function(target) {
        if( !this.element.contains(target) && !target.closest('[aria-controls="'+this.elementId+'"]')) this.toggleMenu(false);
    };

    window.Menu = Menu;

    //initialize the Menu objects
    var menus = document.getElementsByClassName('js-menu');
    if( menus.length > 0 ) {
        var menusArray = [];
        for( var i = 0; i < menus.length; i++) {
            (function(i){menusArray.push(new Menu(menus[i]));})(i);
        }

        // listen for key events
        window.addEventListener('keyup', function(event){
            if( event.keyCode && event.keyCode == 9 || event.key && event.key.toLowerCase() == 'tab' ) {
                //close menu if focus is outside menu element
                menusArray.forEach(function(element){
                    element.checkMenuFocus();
                });
            } else if( event.keyCode && event.keyCode == 27 || event.key && event.key.toLowerCase() == 'escape' ) {
                // close menu on 'Esc'
                menusArray.forEach(function(element){
                    element.toggleMenu(false, false);
                });
            }
        });
        // close menu when clicking outside it
        window.addEventListener('click', function(event){
            menusArray.forEach(function(element){
                element.checkMenuClick(event.target);
            });
        });
        // on resize -> close all menu elements
        window.addEventListener('resize', function(event){
            menusArray.forEach(function(element){
                element.toggleMenu(false, false);
            });
        });
        // on scroll -> close all menu elements
        window.addEventListener('scroll', function(event){
            menusArray.forEach(function(element){
                if(element.menuIsOpen) element.toggleMenu(false, false);
            });
        });
    }
}());