// File#: _2_hiding-nav
// Usage: codyhouse.co/license
(function() {
    var hidingNav = document.getElementsByClassName('js-hide-nav');
    if(hidingNav.length > 0 && window.requestAnimationFrame) {
        var mainNav = Array.prototype.filter.call(hidingNav, function(element) {
                return Util.hasClass(element, 'js-hide-nav--main');
            }),
            subNav = Array.prototype.filter.call(hidingNav, function(element) {
                return Util.hasClass(element, 'js-hide-nav--sub');
            });

        var scrolling = false,
            previousTop = window.scrollY,
            currentTop = window.scrollY,
            scrollDelta = 10,
            scrollOffset = 150, // scrollY needs to be bigger than scrollOffset to hide navigation
            headerHeight = 0;

        var navIsFixed = false; // check if main navigation is fixed
        if(mainNav.length > 0 && Util.hasClass(mainNav[0], 'hide-nav--fixed')) navIsFixed = true;

        // store button that triggers navigation on mobile
        var triggerMobile = getTriggerMobileMenu();

        // init navigation and listen to window scroll event
        initSecondaryNav();
        resetHideNav();
        window.addEventListener('scroll', function(event){
            if(scrolling) return;
            scrolling = true;
            window.requestAnimationFrame(resetHideNav);
        });

        window.addEventListener('resize', function(event){
            if(scrolling) return;
            scrolling = true;
            window.requestAnimationFrame(function(){
                if(headerHeight > 0 && subNav.length > 0) {
                    headerHeight = mainNav[0].offsetHeight;
                    subNav[0].style.top = headerHeight+'px';
                }
                // reset both navigation
                hideNavScrollUp();

                scrolling = false;
            });
        });

        function initSecondaryNav() { // if there's a secondary nav, set its top equal to the header height
            if(subNav.length < 1 || mainNav.length < 1) return;
            headerHeight = mainNav[0].offsetHeight;
            subNav[0].style.top = headerHeight+'px';
        };

        function resetHideNav() { // check if navs need to be hidden/revealed
            currentTop = window.scrollY;
            if(currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
                hideNavScrollDown();
            } else if( previousTop - currentTop > scrollDelta || (previousTop - currentTop > 0 && currentTop < scrollOffset) ) {
                hideNavScrollUp();
            } else if( previousTop - currentTop > 0 && subNav.length > 0 && subNav[0].getBoundingClientRect().top > 0) {
                setTranslate(subNav[0], '0%');
            }
            // if primary nav is fixed -> toggle bg class
            if(navIsFixed) {
                var scrollTop = window.scrollY || window.pageYOffset;
                Util.toggleClass(mainNav[0], 'hide-nav--has-bg', (scrollTop > headerHeight));
            }
            previousTop = currentTop;
            scrolling = false;
        };

        function hideNavScrollDown() {
            // if there's a secondary nav -> it has to reach the top before hiding nav
            if( subNav.length  > 0 && subNav[0].getBoundingClientRect().top > headerHeight) return;
            // on mobile -> hide navigation only if dropdown is not open
            if(triggerMobile && triggerMobile.getAttribute('aria-expanded') == "true") return;
            if( mainNav.length > 0 ) {
                setTranslate(mainNav[0], '-100%');
                mainNav[0].addEventListener('transitionend', function cb(){
                    mainNav[0].removeEventListener('transitionend', cb);
                    Util.addClass(mainNav[0], 'hide-nav--off-canvas');
                });
            }
            if( subNav.length  > 0 ) setTranslate(subNav[0], '-'+headerHeight+'px');
        };

        function hideNavScrollUp() {
            if( mainNav.length > 0 ) {setTranslate(mainNav[0], '0%'); Util.removeClass(mainNav[0], 'hide-nav--off-canvas');}
            if( subNav.length  > 0 ) setTranslate(subNav[0], '0%');
        };

        function setTranslate(element, val) {
            element.style.transform = 'translateY('+val+')';
        };

        function getTriggerMobileMenu() {
            // store trigger that toggle mobile navigation dropdown
            var triggerMobileClass = hidingNav[0].getAttribute('data-mobile-trigger');
            if(!triggerMobileClass) return false;
            var trigger = hidingNav[0].getElementsByClassName(triggerMobileClass);
            if(trigger.length > 0) return trigger[0];
            return false;
        };

    } else {
        // if window requestAnimationFrame is not supported -> add bg class to fixed header
        var mainNav = document.getElementsByClassName('js-hide-nav--main');
        if(mainNav.length < 1) return;
        if(Util.hasClass(mainNav[0], 'hide-nav--fixed')) Util.addClass(mainNav[0], 'hide-nav--has-bg');
    }
}());