/**
 * Page Home
 *
 * 01. Hero
 * 02. Software Tabs/Accordion
 * 03. References
 */

(function (w, d) {

    /**
     * Hero
     *
     * @requires plugins/swiper.js
     *
     * @see https://codepen.io/xgad/post/svg-radial-progress-meters
     */
    (function () {
        var container = d.querySelector('.js-hero');

        if (!container) {
            return;
        }

        var progressValue = d.querySelector('.c-progress__value');
        var circumference = 2 * Math.PI * progressValue.getAttribute('r');

        progressValue.style.strokeDasharray = circumference;
        progressValue.style.transitionTimingFunction = 'linear';

        new Swiper(container, {
            speed: 1600,
            loop: true,
            effect: 'fade',
            autoHeight: true,
            slidesPerView: 1,
            lazy: true,
            preventClicks: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                waitForTransition: false
            },
            on: {
                transitionStart: function () {
                    var duration = this.params.autoplay.delay;

                    progressValue.style.transitionProperty = 'none';
                    progressValue.style.transitionDuration = 'unset';
                    progressValue.style.strokeDashoffset = circumference;

                    setTimeout(function () {
                        progressValue.style.transitionProperty = 'stroke-dashoffset';
                        progressValue.style.transitionDuration = duration + 'ms';
                        progressValue.style.strokeDashoffset = 0;
                    }, 10);
                }
            },
            pagination: {
                el: '.js-hero__fraction',
                type: 'fraction',
            },
        });
    }());


    /**
     * Software Tabs/Accordion
     *
     * @see https://codepen.io/dillansimmons/pen/ppaGYr
     */
    (function () {
        var container = d.querySelector('.js-tabs-to-accordion');

        if (!container) {
            return;
        }

        // Help Functions
        // function show(el) {
        //     var el = d.querySelectorAll(el);
        //     for (i = 0; i < el.length; i++) {
        //         if (el[i].style.display === 'none') {
        //             el[i].style.display = 'block';
        //         }
        //     }
        //     return false;
        // }

        function hide(el) {
            var el = d.querySelectorAll(el);
            for (i = 0; i < el.length; i++) {
                if (el[i].style.display === 'block') {
                    el[i].style.display = 'none';
                }
            }
            return false;
        }

        function fadeIn(el) {
            el.style.opacity = 0;
            el.style.display = "block";
            var tick = function () {
                el.style.opacity = +el.style.opacity + 0.05;
                if (+el.style.opacity < 1) {
                    (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16)
                }
            };
            tick();
        }

        function addClass(el, className) {
            var el = d.querySelectorAll(el);

            for (i = 0; i < el.length; i++) {
                if (el.classList) {
                    el[i].classList.add(className);
                } else {
                    el[i].className += ' ' + className;
                }
            }
        }

        function removeClass(el, className) {
            var el = d.querySelectorAll(el);

            for (i = 0; i < el.length; i++) {
                if (el[i].classList) {
                    el[i].classList.remove(className);
                } else {
                    el[i].className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                }
            }
        }

        // Main function for swapping tabs / content on click
        function tabMagic(el) {
            [].forEach.call(el, function (el) {
                el.addEventListener('click', function () {
                    hide(".js-tabs-to-accordion__content");

                    var activeTab = this.getAttribute("rel");
                    fadeIn(d.querySelector("#" + activeTab));

                    removeClass(".js-tabs-to-accordion__item", "-active");
                    addClass("li[rel^='" + activeTab + "']", "-active");

                    removeClass(".js-tabs-to-accordion__heading", "-active");
                    addClass(".js-tabs-to-accordion__heading[rel^='" + activeTab + "']", "-active");
                })
            })
        }

        // Hide the content and show the first tab
        hide(".js-tabs-to-accordion__content");
        fadeIn(d.querySelector("#tab1"));

        //Desktop Tabs
        var tabLi = d.querySelectorAll(".js-tabs-to-accordion__item");
        tabMagic(tabLi);

        // Drawer Tabs
        var drawer = d.querySelectorAll(".js-tabs-to-accordion__heading");
        tabMagic(drawer);
    }());


    /**
     * Services
     *
     * @requires plugins/swiper.js
     */
    (function () {
        new Swiper('.js-services', {
            spaceBetween: '2.43902%',
            slidesPerView: 1,
            grabCursor: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            pagination: {
                el: '.js-services__pag',
                clickable: true
            },
            breakpoints: {
                // when window width is >= 540px
                540: {
                    slidesPerView: 2,
                },
                // when window width is >= 1100px
                1100: {
                    slidesPerView: 3,
                }
            }
        });
    }());


    /**
     * References
     *
     * @requires plugins/swiper.js
     */
    (function () {
        new Swiper('.js-references', {
            spaceBetween: 40,
            slidesPerView: 'auto',
            freeMode: true,
            grabCursor: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            pagination: {
                el: '.js-references__pag',
                clickable: true
            },
        });
    }());

}(window, document));
